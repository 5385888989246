@font-face {
	font-family: 'Open Sans';
	src:
		url('../../public/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('../../public/fonts/OpenSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src:
		url('../../public/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('../../public/fonts/OpenSans-Bold.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src:
		url('../../public/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('../../public/fonts/OpenSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
	background-color: #f8f6f3;
	font-family: 'Open Sans', Arial, Sans-Serif;
	-webkit-print-color-adjust: exact !important;
	print-color-adjust: exact !important;
}

.MuiScopedCssBaseline-root,
div#root {
	height: 100%;
}

.layout {
	min-width: unset !important;
}

.RaLayout-content {
	overflow: auto !important;
}

.layout,
.MuiScopedCssBaseline-root,
.RaLayout-content {
	background-color: transparent !important;
}

.MuiDrawer-root.RaSidebar-docked {
	height: auto;
}

.MuiTabs-scrollButtons.Mui-disabled {
	display: none;
}

.RaRichTextInput-editorContent > div {
	min-height: 10rem;
}

.RaLayout-appFrame {
	margin-top: 0 !important;
}

@keyframes slideUp {
	0% {
		transform: translateY(1rem);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.recharts-surface {
	overflow: visible !important;
}
